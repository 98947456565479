import React from "react"
import styled from "@emotion/styled"
import mq from "@styles/mq"
import {
  Segment,
  GridRow,
  Grid,
  ListItem,
  Container,
  PopupContent,
  GridColumn,
} from "semantic-ui-react"
import { H2, H6, Paragraph1, H5, H4 } from "@styles/Global.styles"

import theme from "@styles/theme"
import darkTheme from "@styles/dark-theme"
import { select } from "redux-saga/effects"

// Quick Combination Finder

export const SimpleCombinationFinderSegment = styled(({darkTheme,...rest}) => <Segment {...rest} />)`
  margin: 0 !important;
  background-color: transparent !important;
  padding: 80px 0 !important;
`

export const CombinationFinderSegment = styled(Segment)`
  margin: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
`

export const CombinationFinderContainer = styled(Container)``
export const CombinationContentArea = styled.div`
  width: 100%;
  text-align: center;
  margin: auto;
  ${mq({
    maxWidth: ["100%", "1000px", "750px"],
    float: ["none", "none", "none", "left"],
  })}
`

export const QuickCombinationContentArea = styled.div`
  width: 100%;
  text-align: center;
  margin: auto;

  ${mq({
    maxWidth: ["100%", "1000px", "750px"],
  })}
`

export const StyledQuickCombinationContentArea = styled(
  ({ mobileWidth, tabletWidth, desktopWidth, ...props }) => <div {...props} />
)`
  width: 100%;
  text-align: center;
  margin: auto;

  ${props =>
    mq({
      maxWidth: [props.mobileWidth, props.tabletWidth, props.desktopWidth],
    })}
`
export const SimpleCombinationContentArea = styled.div`
  max-width: 490px;
  text-align: center;
  margin: auto;
`

export const FinderTitle = styled(({ margined, ...props }) => (
  <H2 {...props} />
))`
  ${props =>
    props.margined
      ? mq({ marginBottom: ["25px", "25px", "42px"] })
      : "margin-bottom: 0px !important;"};
  color: ${props => (props.color ? props.color : "white")};
`

export const FinderSubTitle = styled(({ ...props }) => (
  <Paragraph1 {...props} />
))`
  margin-bottom: 0;
  color: ${props => (props.color ? props.color : "white")};
`

export const SimpleFinderTitle = styled(({ ...props }) => <H2 {...props} />)`
  ${mq({
    marginBottom: ["0", "20px", "20px"],
  })}
`
export const InfoBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  ${mq({
    marginBottom: ["5px", "10px", "10px"],
  })}
`

export const VehicleTypeOptions = styled.div`
  float: left;
`

export const VehicleTypeListItem = styled(ListItem)`
  ${mq({
    marginLeft: ["6px !important;", "8px !important;", "8px !important;"],
    marginRight: ["6px !important;", "8px !important;", "8px !important;"],
  })}
  .item:first-child {
    margin-left: 0em !important;
    padding-left: 0em !important;
  }
    
`
export const VehicleTypeItem = styled(({ selected, darkTheme, ...rest }) => (
  <div {...rest} />
))`
  color: ${props => props.selected ? (props.color ? props.color : theme.brand.primary) : props.darkTheme ? theme.brand.colors.white : theme.brand.colors.darkGrey};  
  
  line-height: 15px;
  cursor: pointer;
  span{
    border-bottom: ${props =>
    props.selected ? "2px solid " + theme.brand.primary : "none"};
    font-weight: bold;
  }
`

export const QuickHelpInfoDarkTheme = styled.div`
  ${mq({
    paddingLeft: ["6px !important;", "8px !important;", "8px !important;"],
    verticalAlign: ["middle", "super", "super"],
  })}
  display: inline-block;
`

export const QuickHelpInfo = styled.div`
  padding-left: 5px;
  display: inline-block;
`

export const VehicleTypeGrid= styled(Grid)`
  margin: 0px !important;
`

export const VehicleTypeLeftColumn = styled(({selected, ...rest}) => <Grid.Column {...rest} />)`
  background-color: #379D27;
  padding: 8px 13px 3px 6px !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

export const VehicleTypeRightColumn = styled(({selected, ...rest}) => <Grid.Column {...rest} />)`
  background-color: #62AD55;
  ${mq({
    padding: ["8px 4px 6px 4px !important;", "10px 4px 6px 4px !important;", "10px 4px 6px 4px !important;"],
  })}
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

export const CombinationLetterCounterContainer = styled.div`
  //float: right;
`
export const CombinationLetterCounter = styled(({darkTheme, ...rest}) => <H6 {...rest} />)`
    color : ${p => p.darkTheme ? 'white' : 'black'};
    line-height: 15px !important;
    ${mq({
      fontSize: ["14px !important;", "16px !important;", "16px !important;"],
    })}
    font-weight: 700;
`

export const CombinationInputContainer = styled(({ vehicleTheme, darkTheme, ...rest }) => (
  <div {...rest} />
))`
  ${mq({
    borderWidth: ["5px", "8px", "12px"],
    borderRadius: ["10px", "10px", "16px"],
    padding: ["5px", "15px", "20px"],
    maxHeight: ["auto", "240px;", "275px;"],
  })}
  border-style: solid;
  border-color: ${props => props.vehicleTheme?.borderColor};
  background-color: ${props => props.darkTheme ? 'black' : 'white'};  
  color: ${props => props.vehicleTheme?.color};
`

export const CombinationInputContainerRowBottom = styled(GridRow)`
  ${mq({
    paddingBottom: ["1px !important", "0 !important", "0 !important"],
    paddingTop: ["3px !important", "0 !important", "0 !important"],
    marginBottom: ["13px !important", "inherit", "inherit"],
  })}
`

export const CombinationInputContainerRowTop = styled(GridRow)`
  ${mq({
    paddingBottom: ["13px !important", "1rem !important", "1rem !important"],
  })}
`
export const QuickCombinationInputContainer = styled(({ vehicleTheme, darkTheme, ...rest }) => (
  <div {...rest} />
))`
  ${mq({
    borderWidth: ["5px", "10px", "12px"],
    borderRadius: ["10px", "13px", "16px"],
    padding: ["5px", "15px", "20px"],
    maxHeight: ["auto;", "240px;", "275px;"],
    marginBottom: ["5px", "20px", "32px"],
  })}
  border-style: solid;
  border-color: ${props => props.vehicleTheme?.borderColor};
  background-color: ${props => props.darkTheme ? 'black' : 'white'};
  color: ${props => props.vehicleTheme?.color};
  width: 100%;
  max-height: 275px;
  box-shadow: ${props => props.vehicleTheme?.shadow};
`
export const SimpleCombinationInputContainer = styled(GridColumn)`
  border-bottom-style: solid;
  border-bottom-width: 4px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 20px !important;
  border-color: ${theme.brand.colors.black};
  ${mq({
    width: ["100%", "452px", "490px"],
    maxWidth: ["320px", "452px", "490px"],
    paddingBottom: ["10px", "10px", "10px"],
  })}
`

export const CombinationInput = styled(({ vehicleTheme, darkTheme, ...rest }) => (
  <input {...rest} />
))`
  ${mq({
    lineHeight: ["16vw", "143px", "146px"],
    fontSize: ["25vw", "200px", "201px"],
    maxHeight: ["auto;", "165px;", "165px;"],
  })}
  padding: 0 !important;
  width: 100%;
  border: none;
  outline: none;
  text-align: center;
  align-content: center;
  font-family: "NZ Plate Webfont";
  background-color: ${props => props.darkTheme ? 'black' : 'white'};
  color: ${props => props.darkTheme ? 'white' : props.vehicleTheme?.color};
  :focus {
    outline-width: 0;
  }
`
export const StyledCombinationInput = styled.input`
  ${mq({
    lineHeight: ["16vw", "143px", "146px"],
    fontSize: ["25vw", "200px", "201px"],
    maxHeight: ["auto;", "165px;", "165px;"],
  })}
  padding: 0 !important;
  width: 95%;
  border: none;
  outline: none;
  text-align: center;
  align-content: center;
  font-family: "NZ Plate Webfont";
  background-color: #191919;
  color: ${theme.brand.colors.darkGrey};
  :focus {
    outline-width: 0;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${theme.brand.colors.darkGrey};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${theme.brand.colors.darkGrey};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${theme.brand.colors.darkGrey};
  }
`
export const SimpleCombinationInput = styled.input`
  ${mq({
    fontSize: ["86px", "80px", "80px"],
    lineHeight: ["86px", "81px", "81px"],
    marginTop:['15px', '15px','15px']
  })}
  border: none;
  padding: 0;
  background-color: transparent;
  width: 100%;
  outline: none;
  font-family: "NZ Plate Webfont";
  text-align: center;
  :focus {
    outline-width: 0;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${theme.brand.colors.lightGrey};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${theme.brand.colors.lightGrey};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${theme.brand.colors.lightGrey};
  }
`
export const CombinationInputGrid = styled(
  ({ mobileMargin, tabletMargin, desktopMargin, ...rest }) => <Grid {...rest} />
)`
  position: absolute;
  ${props =>
    mq({
      top: [
        `${props.mobileMargin} !important`,
        `${props.tabletMargin} !important`,
        `${props.desktopMargin} !important`,
      ],
    })}
`
export const CombinationActionContainer = styled(({darkTheme, ...rest}) => <div {...rest} />)`
  ${mq({
    paddingTop:["30px","30px","30px"],
    paddingBottom:["10px","20px","20px"]
  })};
  button {
    ${mq({
      minWidth: ["100%", "230px !important", "230px !important"],
      maxWidth: ["100%", "100% !important", "100% !important"]
    })}
  }
    .redirect-text{
      color: ${p => p.darkTheme ? 'white' : "black"};
    }
`

export const StyledCombinationActionContainer = styled.div`
  margin-top: 15px;
`
//todo: #db2828 color code need to be confirmed
export const CombinationSearchResult = styled(
  ({ available, color, ...rest }) => <div {...rest} />
)`
  color: ${props =>
    props.available
      ? props.color
        ? props.color
        : theme.brand.primary
      : theme.brand.colors.error};
  font-weight: bold;
  margin-top: 20px;
  ${mq({
    fontSize: ["16px", "22px", "22px"],
    lineHeight: ["22px", "22px", "28px"],
  })}
  &&&& {
    margin-bottom: 0;
  }
`

export const CombinationSearchResultSection = styled.div``

export const RedesignMessage = styled.div`
    color: #eee;
    font-size: 14px;
    font-weight: 500;
    ${mq({
      marginTop: ["0px !important", "12px !important", "12px !important"],
      marginBottom: ["10px !important", "20px !important", "20px !important"],
    })}
    > a {
        color: black;
        margin-top: 6px !important;
        text-decoration: underline;
        &:hover {
            color: black;
        }
    }
  }
`

export const ResetCombination = styled(Paragraph1)`
  padding-top: 5px;
  cursor: pointer;
  text-decoration: underline;
  color: #b7b7b7;
`

export const PlateHoleTopLeft = styled.div`
  position: relative;
  content: "";
  background-color: #a1a1a1;
  top: -40px;
  left: -76px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  z-index: 999;
  float: left;
`

export const PlateHoleTopRight = styled.div`
  position: relative;
  content: "";
  background-color: #a1a1a1;
  top: -40px;
  left: 76px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  z-index: 999;
  float: right;
`
export const PlateHole = styled(({  vehicleTheme, floated, ...rest }) => <div {...rest} />)`
  content: "";
  background-color: ${props =>props.vehicleTheme?.borderColor};
  z-index: 999;
  float: ${props => (props.floated === "left" ? "left" : "right")};
  ${mq({
    borderRadius: ["5px", "13px", "16px"],
    width: ["5px", "13px", "16px"],
    height: ["5px", "13px", "16px"],
  })}
`

export const RestylePageHeading = styled(({darkTheme, ...rest}) => <H2 {...rest}/>)`
  margin-bottom: 10px;
  margin-top: 5px;
  color: ${p => p.darkTheme ? 'white' : 'black'};
  ${mq({
    fontSize: ["26px", "36p", "36p"],
  })}
  font-weight: 700;
`

export const PlateHoleTopLeftMiddle = styled.div`
  position: relative;
  content: "";
  background-color: #a1a1a1;
  top: -40px;
  left: 80px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  z-index: 999;
  float: left;
`

export const PlateHoleTopRightMiddle = styled.div`
  position: relative;
  content: "";
  background-color: #a1a1a1;
  top: -40px;
  left: -80px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  z-index: 999;
  float: right;
`

export const PlateHoleBottomLeft = styled.div`
  position: relative;
  content: "";
  background-color: #a1a1a1;
  bottom: -30px;
  left: -76px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  z-index: 999;
  float: left;
`

export const PlateHoleBottomRight = styled.div`
  position: relative;
  content: "";
  background-color: #a1a1a1;
  bottom: -30px;
  left: 70px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  z-index: 999;
  float: right;
`
export const PlateHoleBottomLeftMiddle = styled.div`
  position: relative;
  content: "";
  background-color: #a1a1a1;
  bottom: -30px;
  left: 80px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  z-index: 999;
  float: left;
`

export const PlateHoleBottomRightMiddle = styled.div`
  position: relative;
  content: "";
  background-color: #a1a1a1;
  bottom: -30px;
  left: -80px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  z-index: 999;
  float: right;
`

export const VehiclyTypePopupContainer = styled(PopupContent)`
  padding: 25px;
  width: 300px;
`

export const CombinationInputRow = styled(GridRow)`
  ${mq({
    padding: ["0 !important", "0", "0"],
  })}
`

export const CombinationFinderHiddenElement = styled.div`
  &.hidden {
    display: none;
  }
`
export const RestyleGuideContainer = styled.div`
  background-color: ${theme.brand.colors.white};
  border-radius: 10px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
  ${mq({
    padding: [
      "20px 20px 15px 20px",
      "30px 30px 15px 30px",
      "30px 30px 15px 30px",
    ],
    margin: ["0px 0px 0px 0px", "0px 0px 0px 0px", "56px 20px 20px 60px"],
  })}
`
export const RestyleGuideHeading = styled(H5)`
  font-size: 18px !important;
  padding-bottom: 10px;
  border-bottom: 2px solid ${theme.brand.colors.darkGrey};
`
export const RestyleGuideDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${theme.brand.colors.darkGrey};
`
export const RestyleForNewPlateMessage = styled(({darkTheme, ...rest}) => <div {...rest} />)`
  font-size: 15px;
  padding-top: 10px;
  line-height: 22px;
  color: ${p => p.darkTheme ? '#eee' : 'black'};
`
export const SearchResultWrapper = styled.div`
  margin-top: 22px;
`
export const SearchResultButtonWrapperColumn = styled(({maxWidth, marginRight, ...rest}) => <Grid.Column {...rest}/>)`
  padding-top: 10px !important;
  padding-bottom: 10px !important; 
  max-width: ${props => (props.maxWidth)}%;
  margin-right: ${props => (props.marginRight)}px;
  ${mq({        
    paddingLeft: ["0px !important", "20px !important", "30px !important"],
    paddingRight: ["0px !important", "20px !important", "30px !important"],
})}
  > a {
    margin-top: 0 !important;
  }
`
export const StyledQuickCombinationInputContainer = styled.div`
  width: 100%;
  box-shadow: 0px 6px 16px #00000029;
`
export const StyledQuickCombinationImage1Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const StyledQuickCombinationImageSegment = styled(Segment)`
  && {
    border: none;
    padding: 0;
    margin: 0;
  > a {
    margin-top: 0 !important;
  }
`
export const StyledQuickCombinationImageContainer = styled.div`
  position: relative;
  box-shadow: 0px 6px 16px #0000001f;
  background-color: transparent;
  border-radius: 20px;
  margin-bottom: 15px;
`
export const StyledCombinationFinderBgImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
`
export const AdsImageWrapper = styled.img`
  width: 100%;
  margin-top: 30px;
`
export const SimpleSearchResultButtonWrapperColumn = styled(Grid.Column)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  ${mq({        
    paddingLeft: ["0px !important", "0px !important", "0px !important"],
    paddingRight: ["0px !important", "0px !important", "0px !important"]
})}
  > a {
    margin-top: 0 !important;
  }
`
export const MutipleButtonWrapper = styled(Grid.Column)`

`
export const CombinationLetterCounterInSelector = styled(({darkTheme, ...rest}) => <H6 {...rest} />)`
    color : white;
    line-height: 15px !important;
    ${mq({
      fontSize: ["14px !important;", "16px !important;", "16px !important;"],
    })}
    font-weight: 700;
`
export const BreadCrumb = styled.div`
    color: ${theme.brand.colors.white};
    text-align: right;
    font-weight: 500;
    font-size:16px;
    span{
      color: #3DAE2B;
      font-weight: 700;
    }
    a{
      color:#fff;
    }
      a:hover{
        color: #3DAE2B;
      }
    svg {
      margin-left: 8px;
      margin-right: 8px;
    }
`;
export const ButtonWrapper = styled.span`
    a{
      margin-top: 0px !important;
    }
`
